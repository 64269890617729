// Here you can add other styles
.clickOption {
  cursor: pointer;
  border: 1px solid #ccc;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}
.clickOption:hover {
  color: #321fdb;
  border: 1px solid #321fdb;
  font-weight: 500;
}
.clickButton {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 41px;
  text-align: center;
  color: red;
  padding: 2px 3px;
  margin-left: 20px;
}

// @import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Mooli&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Titillium+Web:wght@300;400;600;700&display=swap');:root {
  --bs-brand: #ffbd00;
  --bs-brand: #ffbd00;

  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #000015;
  --cui-white: #fff;
  --cui-gray: #8a93a2;
  --cui-gray-dark: #636f83;
  --cui-gray-100: #ebedef;
  --cui-gray-200: #d8dbe0;
  --cui-gray-300: #c4c9d0;
  --cui-gray-400: #b1b7c1;
  --cui-gray-500: #9da5b1;
  --cui-gray-600: #8a93a2;
  --cui-gray-700: #768192;
  --cui-gray-800: #636f83;
  --cui-gray-900: #4f5d73;
  --cui-primary: #00e9c1;
  --cui-secondary: #372b3a;
  --cui-success: #2eb85c;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #ebedef;
  --cui-dark: #4f5d73;
  --cui-primary-rgb: 0, 233, 193;
  --cui-secondary-rgb: 157, 165, 177;
  --cui-success-rgb: 46, 184, 92;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 235, 237, 239;
  --cui-dark-rgb: 79, 93, 115;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 0, 0, 21;
  --cui-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
    'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
  --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(44, 56, 74, 0.95);
  --cui-body-color-rgb: 44, 56, 74;
  --cui-body-bg: #fff;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-emphasis-color: #000015;
  --cui-emphasis-color-rgb: 0, 0, 21;
  --cui-secondary-color: rgba(44, 56, 74, 0.681);
  --cui-secondary-color-rgb: 44, 56, 74;
  --cui-secondary-bg: #d8dbe0;
  --cui-secondary-bg-rgb: 216, 219, 224;
  --cui-tertiary-color: rgba(44, 56, 74, 0.38);
  --cui-tertiary-color-rgb: 44, 56, 74;
  --cui-tertiary-bg: #ebedef;
  --cui-tertiary-bg-rgb: 235, 237, 239;
  --cui-high-emphasis: rgba(44, 56, 74, 0.95);
  --cui-medium-emphasis: rgba(44, 56, 74, 0.681);
  --cui-disabled: rgba(44, 56, 74, 0.38);
  --cui-high-emphasis-inverse: rgba(255, 255, 255, 0.87);
  --cui-medium-emphasis-inverse: rgba(255, 255, 255, 0.6);
  --cui-disabled-inverse: rgba(255, 255, 255, 0.38);
  --cui-heading-color: inherit;
  --cui-link-color: #372b3a;
  --cui-link-color-rgb: 50, 31, 219;
  --cui-link-decoration: underline;
  --cui-link-hover-color: #372b3a;
  --cui-link-hover-color-rgb: 40, 25, 175;
  --cui-code-color: #d63384;
  --cui-highlight-bg: #fff3cd;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #d8dbe0;
  --cui-border-color-translucent: rgba(0, 0, 21, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-xxl: 2rem;
  --cui-border-radius-2xl: var(--cui-border-radius-xxl);
  --cui-border-radius-pill: 50rem;
  --cui-box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15);
  --cui-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075);
  --cui-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 21, 0.175);
  --cui-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 21, 0.075);
  --cui-focus-ring-width: 0.25rem;
  --cui-focus-ring-opacity: 0.25;
  --cui-focus-ring-color: rgba(50, 31, 219, 0.25);
  --cui-form-valid-color: #2eb85c;
  --cui-form-valid-border-color: #2eb85c;
  --cui-form-invalid-color: #e55353;
  --cui-form-invalid-border-color: #e55353;
}
body,
header {
  font-family: 'Poppins', sans-serif;
}
a,
a:hover {
  text-decoration: none;
  color: #372b3a;
}
.link{
      color: #00E9C1;
    font-weight: 600;
}
.brandbg{background: var(--cui-primary);}
.logo {
  padding: 25px 25px 85px;
}
.card-list .template-1 .logo {
  border-top-left-radius: var(--cui-border-radius-xxl) !important;
  border-top-right-radius:  var(--cui-border-radius-xxl) !important;;
}
.card-list .template-1 .logo img {max-width: 200px;height: auto;}
.editcard-pod .template-1 .logo img, .editcard-pod .template-2 .logo img{
max-width: 200px;height: auto;
}

.template-1+.add-wal{margin-top: 30px !important;}
.template-1.vccard .logo  img, .template-2.vccard .logo  img{max-width: 200px;height: auto;}
.avatar img {
  // width: 123px;
  // height: 123px;
  // object-fit: cover;
  // border-radius: 50%;
  // border: solid 5px var(--bs-white);
  // margin-top: -60px;
}
.avatar.edit-card-avatar {
  width: 125px;
  height: 125px;

  margin-top: -60px;
}
.avatar.edit-card-avatar img {
  height: 125px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 5px #fff;
  // width: 123px;
  // height: 123px;
  // object-fit: cover;
  // border-radius: 50%;
  // border: solid 5px var(--bs-white);
  // margin-top: -60px;
}
.user-info h2 {
  font-weight: 600;
  font-size: 25px;
}
.user-info h3 {
  font-weight: 400;
  font-size: 20px;
}
.user-info h2,
.user-info h3 {
  margin: 0;
}

.user-social ul {
  list-style: none;
      max-width: 500px;flex-wrap:wrap;gap: 20px;
}
.user-social-ys  ul {
      list-style: none;
    max-width: 500px;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 155px !important;
    padding-left: 0;
}
.vccard .user-social-ys  ul {

    margin-left: 105px !important;

}
.user-social li:first-child {
  margin: 0;
}
.user-social li {
  width: 48px;
  height: 48px;
  border-radius: 5px;
}
.user-social li svg {
  height: 1.7em;
}
.user-social li a {
  color: #372b3a;
  line-height: 1;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
main, footer {
  max-width: 780px;
  margin: auto;
      width: 100%;
      padding-bottom: 70px;
}
main{padding-bottom: 0;}
main.template-1{padding-bottom: 0px;}
.editcard-pod main{border-bottom-left-radius: 2em; border-bottom-right-radius: 2em;}
.card .card-info li {
  margin-bottom: 20px;
}
.card .card-info li a {
  display: flex;
}
.card .card-info .svg-inline--fa {
  height: 1.5em;
  vertical-align: middle;
  margin-right: 8px;
}
.card-header h3 {
  font-weight: 500;
  font-size: 18px;
}

.card-share svg {
  display: block !important;
  height: 1.5em;
  margin: auto;
  margin-bottom: 5px;
}
.add-contact {
  width: calc(100% - 2rem);
  // margin-bottom: -15px;
  // max-width: 780px;
  max-width: inherit;
    /* margin: auto; */
    /* display: flex; */
    /* justify-content: center;
    transform: translateX(-50%);
    left: 50%;
    bottom: 15px;*/
    width: auto;
    color: #fff !important;
    // border:solid 1px #000;
    background-color: #000;max-width: 300px; margin: auto;
}

.add-contact-profile{
  max-width: 270px; margin:0 0 25px 115px !important;
}
// .add-contact {
//   width: calc(100% - 2rem);
//   margin-bottom: -15px;
//   max-width: 780px;


// }
.add-contact small{font-size: 70%; font-weight: 400;display: block;}
.add-contact a {
  display: block;
  font-weight: 500;
  cursor: pointer;color: #fff !important;

}
.add-contact-profile  a{justify-content: center;}
.add-contact a .fa-address-book{height: 3em; margin-right: 20px;}

footer {
  margin-bottom: 100px;
}
@media(max-width:991px){
  .add-contact{
    // max-width: calc(720px - 25px);
    // width: 100%;
  }
}
@media screen and (max-width: 800px){
main {
    margin-left: 0px !important;
     margin-right: 20px !important;
}
.add-contact-profile{
  margin:0 0 25px 85px !important;
}
}
@media(max-width:767px){
  .add-contact{
    // max-width: calc(542px - 25px);
   }
}
@media(max-width:575px){
  .add-contact{
       // max-width: inherit;
    width: calc(100% - 25px);}
    .add-contact-profile{
  margin:0 0 25px 15px !important;
}
}
//
.error {
  color: #ff0000;
}
.login-box{

}
.loginpod,
.registpod {
  border-radius: 0% 15% 15% 0%/50% !important;
  position: relative;
  z-index: 1;
}
.registpod {
  border-radius: 0% 15% 15% 0%/50% !important;
  position: relative;
  z-index: 1;
}
.login-signup {
  padding-left: 90px;
  margin-left: -90px !important;
  border-radius: 0 !important;
}
.reg-signup {
  padding-left: 100px;
  margin-left: -70px !important;
  border-radius: 0 !important;
  flex: 1.3 0 !important;
  padding-right: 25px;
}

@media(max-width:767px){
  .loginpod,
.registpod, .login-signup, .reg-signup {
     flex: 0 0 100% !important;
    border-radius: 0 !important;
    margin-left: 0 !important;
}
.login-signup, .reg-signup{
  padding: 0 !important;
}
.registpod{order:2}
}
.form-control,
.form-select {
  background-color: #f5f5f5;
  border-radius: 0 !important;
}
.btn-primary {
  --cui-btn-color: #372b3a;
  --cui-btn-bg: #00E9C1;
  --cui-btn-border-color: #00E9C1;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #372b3a;
  --cui-btn-hover-border-color: #372b3a;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #372b3a;
  --cui-btn-active-border-color: #372b3a;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #372b3a;
  --cui-btn-disabled-border-color: #372b3a;
  border-radius: 5px !important;
  // border-top-left-radius: 10px !important;
  // border-bottom-right-radius: 10px !important;
}
.btn-ghost {
  background-color: transparent !important;
}
.bg-primary {
  --cui-bg-opacity: 1;
  background-color: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity)) !important;
}
.text-secondary {
  --cui-text-opacity: 1;
  color: rgba(var(--cui-secondary), var(--cui-text-opacity)) !important;
}

.fblogin > div {
  justify-content: center;
}
.fblogin > div div:nth-child(3) {
  width: auto !important;
}
.log-soc-btns button > div div:nth-child(3), .log-soc-btns button > div div:nth-child(2){
display: none;
}
.log-soc-btns{width: auto;}

.header.header-sticky {
  margin: 30px;
}

.sidebar {
  --cui-sidebar-bg: #372b3a;
}

.header .avatar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 5px var(--bs-white);
  margin-top: 0;
}

.modal-content {
  border-radius: 0;
  outline: 0;
}
.modal-header {
  background: #00e9c1;
  border-radius: 0;
}
.card-common .card-header {
  background: var(--cui-primary);
  // border-radius: 0;
  // border: 0;
}
.sharecard {
  margin-left: -50px;
  right: 10px;
  top: 10px;
  height: 44px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--cui-secondary);
  background-color: var(--cui-nav-pills-link-active-bg);
  border-bottom: solid 4px var(--cui-primary);
  border-radius: 0;
}

.btn-add {
  background-color: var(--cui-white);
  cursor: pointer;
}

#pills-contact .col {
  flex: 20%;
}

#pills-contact .clickButton {
  width: 25px;
  display: inline-block;
  height: 25px;
}
#pills-contact .clickOption:hover {
  color: var(--cui-primary);
  border: 1px solid var(--cui-primary);
  font-weight: 500;
}

.icon-medium {
  cursor: pointer;

  background: #372b3a;
  padding: 6px 7px;
  border-radius: 50%;
}
.icon-medium path {
  fill: #fff !important;
}
.sharebox-container {
  top: 50px;
  right: 0px;
}
.sharebox-container .Demo__container {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
  border-radius: 0.5rem;
  padding: 0.75rem !important;
  // background-color: #fff;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
}
.sharebox-container .Demo__container .Demo__some-network {
  /*flex: 1 0 12%;  The important bit. This percentage decides your columns.
 The percent can be px. It just represents your minimum starting width.
  */
  margin: 0 5px 2px;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
  position: relative;
}
.sharebox-container .Demo__container .Demo__some-network.facebookicn:before {
  content: '';
  position: absolute !important;
  width: 0;
  height: 0;
  margin-left: 0em;
  top: 3px;
  right: -12px;
  box-sizing: border-box;
  border: 0.5em solid black;
  border-color: transparent transparent #fff #fff;
  transform-origin: 0 0;
  transform: rotate(136deg);
  /* box-shadow: -2px 3px 3px 0 rgba(0, 0, 0, 0.2); */
  /* border-radius: 6px;*/
}
.Demo__some-network__share-count {
  display: none;
}

.template-2 .card .card-info {
  border-bottom: solid 1px #fff;
}
.template-2 .card .card-info li {
  margin-bottom: 20px;
  border-top: solid 1px #fff;
  padding-top: 20px;
}
.template-2 .card .card-info li a {
  display: flex;word-break: break-all;
}


.card-list .template-2 .logo img,  .card-list .template-2 .profilepic {
    width: 90px !important;
  }
  .card-list .template-2 .profilepic{
        height: 90px;
    object-fit: cover;
  }
  .card-list .template-2 .user-info,  .card-list .template-2 .card-body,  .card-list .template-2 .user-pod {
    padding: 0 !important;
  }

 .card-list .template-2 h2,  .card-list .template-2 .h2 {
   font-size: 25px;
}
.card-list .template-2  h3, .card-list .template-2  .h3 {
    font-weight: 500;
    font-size: 18px;
}

.card-list .template-2  .fs-4 {
    font-weight: 500;
    font-size: 18px !important;
}
.circle-100{border-radius: 50% !important;}
.btn-circle{    width: 44px;
    height: 44px;
    padding: 0;
    line-height: 44px;border-radius: 50% !important;}
    .btn-edit{


    }

    // .mt-n3{margin-top: -50px;}
    // .template-2+.mt-n3{margin-top: -60px;}
    .btns-pod{    max-width: 80px;
    /* width: auto; */
    display: flex;
    justify-content: center;position: absolute;
    top: 20px;
    left: 20px;}
    .template-2 .profilepic {
    width: 100px !important;
    height: 100px;
    object-fit: cover;
}
@media screen and (max-width: 800px) {
  main {
    margin-left: 20px;
    margin-right: 20px;
  }
  .template-2 .logo img {
    width: 100px;
  }
  .template-2 .user-info {
    padding: 0 !important;
  }
  .template-2 .card-body {
    padding: 0 !important;
  }

  .template-2 .profilepic {
    width: 70px !important;

    height: 70px;
    object-fit: cover;

  }
  .template-2 .user-pod {
    padding: 0 !important;
  }
}

.profileImage {
  width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #5FC3AF;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    margin: 0px 0;
}


.sticky-qeta{
         top: 50%;
    right: 0;
    background: #fff;

    width: 100%;
    border-radius: 20px 0;
    max-width: 100px;
        transform: translateY(-50%);
        z-index: 1030;
}
.sbtn{
     border-bottom: solid 1px #ccc;
    padding: 10px !important;
    /* display: block; */
    margin: 0;
    width: 100%;
    border-radius: 0;
}
.vccard main{padding-bottom: 0;}
.qr-vcard{    position: absolute;
    padding: 30px;}

    .qrcode-img{
      margin-top: 50px;
          width: fit-content !important;
          margin-left:auto !important;
          margin-right: auto !important;
          max-width: 400px;
    }
    @media(max-width:1499px){
      .qrcode-img{
          max-width: 250px;
    }
    }
    .card-name{
      background-color: rgb(243, 158, 196);
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
    padding: 20px;

    }
 .template-1+.card-name{

    }
    .template-1+.addto-wallet{
      margin-top: 30px;
    }
@media (min-width:1999px){
.row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 25%;
}
}
.editcard-pod  .template-2 .profilepic {
    width: 150px !important;
        height: 150px;
    object-fit: cover;
}
    @media(min-width:991px) and (max-width:1499px){
      .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 50% !important;
}

.editcard-pod .col-lg-6{ width: 60% !important;}
.editcard-pod .col-lg-6+.col-lg-6{ width: 40% !important;}
.editcard-pod .template-2 .logo img, .editcard-pod  .template-2 .profilepic {
    width: 90px !important;
}
.editcard-pod  .template-2 .profilepic {
    width: 90px !important;
        height: 90px;
    object-fit: cover;
}
.user-social-ys ul{margin-left: 90px !important;}
    }
   @media(max-width:990px){
    .editcard-pod  .template-2 .profilepic {
    width: 70px !important;
        height: 70px;
    object-fit: cover;
}
.user-social-ys ul{margin-left: 70px !important;}

   }
    @media (max-width:800px){
.vccard .user-social-ys ul {
    margin-left: 75px !important;
}
    }
    @media (max-width:767px)
{
  .sbtn span{display: none;}
  .sticky-qeta {

    max-width: 50px;
}
}
    .db-downloads-col{
      background-color: #EBEDEF;
      padding: 5px;
      margin-bottom: 5px;overflow: hidden;
    }
     .db-downloads-col strong{
      float: right;
     }
    .table-hover > tbody > tr:hover .db-downloads-col{
      background-color: #fff;
      padding: 5px;
      margin-bottom: 5px;
    }
    .user-dropdown .dropdown-header.bg-light{
      background-color: #00E9C1 !important;
      color:#372B3A;
    }
    .user-dropdown li{
      border-bottom: solid 1px rgba(0, 0, 21, 0.175);
    }
    .prof-qrimg{max-width: 300px;}
    @media(max-width:499px){
      .prof-qrimg {
    max-width: 250px;
}
    }

    @media(max-width:576px){
      .header.header-sticky {
    margin: 30px 15px;
}
.body.flex-grow-1.px-3{padding-left: 0 !important; padding-right: 0 !important;}
.footer *{width: 100% !important; text-align: center !important;}
.mt-xs-2{margin-top: 1em;}
.graph-label{font-size: 0.75em;
    padding-top: 10px !important;
    line-height: 1.2em;
    padding-bottom: 5px;}
    input[type="file"]{font-size: 0.85em;}
    .sticky-qeta {

    // max-width: 80px;
}
.user-social-ys ul{margin-left: 0px !important;}
.vccard .user-social-ys ul {
    margin-left: 10px !important;
}
    }
.cursor-pointer {
  cursor: pointer;
}
.copy-btn{
  background-color: #372B3A;
  color: #fff;
  margin-right: 5px;
}
.cropper-point {
  background-color: #fff !important;
}
.cropper-line {
  background-color: #fff !important;
}

.rounded .cropper-crop-box, .rounded  .cropper-view-box {
  border-radius: 50%;
}

.rounded .cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.mooli-text{font-family: 'Mooli', sans-serif;
}

.titillium-text{font-family: 'Titillium Web', sans-serif;}
@media (min-width: 992px) {
  .modal-custom {
      --cui-modal-width: 500px;
  }
}

.table-secondary th, .table-secondary td{
  background-color: #e2e3e5;
}
.table td, .table th{padding: 5px;}
.table tr:nth-child(odd) td{
  background-color: #f5f5f5;
}


